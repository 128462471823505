import React, { useEffect } from 'react';

import { localStorage } from '@/common/utils';

function App() {
  useEffect(() => {
    localStorage.clear();
  }, []);

  return <div className="App"></div>;
}

export default App;
